export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      resource: 'settings',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/onboarding',
    name: 'settings-onboarding',
    component: () => import('@/views/settings/SetOnboarding/SetOnboarding.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/userrole',
    name: 'settings-userrole',
    component: () => import('@/views/settings/Permissions/role-list/RoleList.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },
  
];
